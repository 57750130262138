$white: #ffffff
$transparent-white: #ffffff66
$desert-storm: #f8f8f8
$soapstone: #fcfcfc
$black: #000000
$onyx: #111111
$dark-jungle-green: #222222
$ironside-grey: #666666
$davy-grey: #555555
$primary-shadow-color: #00000080
$secondary-shadow-color: #000000b3
$primary-transition: all 0.1s ease
$secondary-transition: 0.3s ease
$m1: -18
$m2: 18

.piano 
  display: flex
  justify-content: center
  background-image: linear-gradient( -65deg, $black, $dark-jungle-green, $black, $ironside-grey, $dark-jungle-green 75%)
  box-shadow: 0.1rem 0.1rem 0.1rem $black
  padding: 0.9rem 0 6rem
  position: relative

.key 
  height: 100%
  width: 9rem
  cursor: grab
  position: relative
  border-radius: 0.1rem
  margin: 0 0.1rem
  background-image: linear-gradient(-30deg, $desert-storm, $white)
  box-shadow: inset 0 0.2rem 0 $white, inset 0 -0.2rem 0 $white, inset 0.2rem 0 0 $white, inset -0.2rem 0 0 $white, 0 0.5rem 0.4rem $secondary-shadow-color,    inset 0 -0.2rem 0 $white, inset 0.2rem 0 0 $white,    inset -0.2rem -0.2rem 1.6rem $primary-shadow-color, -0.3rem 0.5rem 0.7rem $primary-shadow-color


.key:active:not(.sharp),
.playing 
  transition: $primary-transition
  box-shadow: inset 0 0.2rem 0 $white, inset 0 -0.2rem 0 $white, inset 0.2rem 0 0 $white, inset -0.2rem 0 0 $white, 0 0.4rem 0.4rem $secondary-shadow-color, inset 0 -0.2rem 0 $white, inset 0.1rem 0 0 $white, inset -0.2rem -0.2rem 1.6rem $black,  -0.4rem 0.5rem 0.7rem $primary-shadow-color 
  height: 98.9% 
  cursor: grabbing


.sharp 
  z-index: 1
  margin-top: -0.1rem !important
  position: absolute !important
  height: 45% !important
  width: 6rem
  background-image: linear-gradient(-20deg, $dark-jungle-green, $black, $dark-jungle-green) !important
  box-shadow: inset 0 -0.1rem 0.2rem $transparent-white, 0 0.2rem 0.3rem $primary-shadow-color !important
  border-style: solid
  border-color: $ironside-grey $dark-jungle-green $onyx $davy-grey
  border-width: 0.2rem 0.4rem 1.2rem


.sharp[data-note="w"] 
  margin-right: $m1 + 92rem

.sharp[data-note="e"] 
  margin-right: $m1 + 73.5rem

.sharp[data-note="t"] 
  margin-right: $m1 + 37rem

.sharp[data-note="y"] 
  margin-right: $m1 + 18.5rem

.sharp[data-note="u"] 
  margin-left: $m2 + 0.5rem

.sharp[data-note="o"] 
  margin-left: $m2 + 37rem

.sharp[data-note="p"] 
  margin-left: $m2 + 55.5rem

.sharp[data-note="]"] 
  margin-left: 91.5rem

.sharp:active,
.playing 
  transition: $primary-transition
  border-bottom-width: 0.4rem
  cursor: grabbing

