.burger-menu 
  position: absolute
  right: 18px
  top: 26px
  z-index: 3
  padding-left: 8px
  div 
    width: 30px
    border-radius: 4px
    padding: 2px
    background: white
    margin: 4px
    transition: 0.25s  

.menu-opened 
  border-radius: 7px
  div 
    width: 18px  

.menu-opened div:nth-child(1) 
  transform: translate(0px, 8px) rotate(-45deg)
  width: 30px

.menu-opened div:nth-child(2) 
  opacity: 0

.menu-opened div:nth-child(3) 
  transform: translate(0px, -8px) rotate(45deg)
  width: 30px
