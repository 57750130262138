$main-color: rgb(22, 124, 192)
// $main-color: rgb(32, 32, 32)
$icon-hover: rgba(255, 255, 255, 0.781)

.header 
  position: relative
  background-color: $main-color
  display: grid
  grid-template-columns: 20fr 1fr
  text-align: center
  align-items: center
  color: white
  width: 100%
  height: 77px
  font-size: 18px
  font-family: "Lato", sans-serif
  letter-spacing: 3.2px
  .title 
    font-size: 29px
  a 
    color: white
    text-decoration: none  
    display: flex
    justify-content: center
    align-items: center
    &:hover 
      color: $icon-hover
    .login_icons 
      display: flex
      align-items: center
      position: absolute
      right: 67px
      top: 24px
      // z-index: 3

@media (max-width: 568px) 
  .header 
    grid-template-columns: 8fr 5fr
    .title 
      font-size: 22px
    
  

