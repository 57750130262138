$base-color: rgb(41, 128, 185)
$hover-color: rgb(97, 182, 238)

.modal 
  position: fixed
  z-index: 5
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.4)

  display: flex
  justify-content: center
  align-items: center
  // opacity: 0
  // pointer-events: none
  transition: 0.2s

.modal_content 
  margin-top: -70px
  background-color: white
  border-radius: 20px
  padding: 18px
  position: relative
  min-width: 375px
  min-height: 240px
  display: flex
  align-items: center
  justify-content: center


.closeIcon 
  position: absolute
  top: 20px
  right: 20px
  cursor: pointer
  z-index: 10


.login,
.signup 
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 15px
  background-color: #fff
  background-color: transparent
  .login-info 
    font-family: Charter, serif
    font-size: 22px
    letter-spacing: 1.3px
    padding-bottom: 10px
  
  .field 
    display: flex
    width: 435px
    // justify-content: space-between
    justify-content: center
    align-items: center
    margin: 7px
    color: rgb(5, 5, 5)
    font-size: 19px
    font-family: "Cardo", serif
    font-family: "Charter", serif
    letter-spacing: 2px
  
  .login_buttons button 
    width: 124px
    height: 45px
    margin: 10px
    font-size: 17px
    font-family: "Cormorant", serif
    font-family: "Charter", serif
    letter-spacing: 2px
    color: rgb(255, 255, 255)
    background-color: $base-color
    border-radius: 20px
    border: 0
    transition-duration: 0.4s
    &:hover 
      background-color: $hover-color
      color: white
    
  
  .field input 
    padding-left: 22px
    font-size: 20px
    font-family: "Cormorant", serif
    width: 222px
    width: 70%
    height: 39px
    border-radius: 20px
    border: 1px solid grey
  

  p 
    margin-top: 11px
    text-decoration: underline
    font-size: 16.4px
    font-family: "Charter", serif
    letter-spacing: 1.6px
    color: black
  

@media (max-width: 768px) 
  .login,
  .signup 
    .field 
      display: flex
      width: 90%
      input 
        font-size: 18px
        width: 100%
        height: 34px
      
    
  

