$main-color: rgb(22, 124, 192)
$second-color: #4329d4
  
.buttons 
  display: flex
  justify-content: center
  align-items: center
  button 
    margin: 26px 12px 12px
    padding: 9px 18px
    font-family: 'Lato', sans-serif
    font-size: 16px
    border-radius: 128px
    color: white

    &:hover
      background: $main-color
      color: white

  .action_buttons
    display: flex
    justify-content: center
  .action_button
    color: black
    background: white
    padding: 0px 0px
    display: flex
    align-items: center
    .play, .record, .stop
      border-radius: 50%
      &:hover
        color: white
    .play:hover
      background: green
    .record:hover
      background: red
    .stop:hover
      background: black

  .save
    font-weight: 600
    font-size: 15px
    border-radius: 50%
    background: #828282
    background: #ffffff
    border: 2px solid black
    color: white
    color: black
    padding: 9px 9px
    &:hover
      color: white 
      background: $main-color
      border: 2px solid $main-color

  .sound_buttons
    display: flex
    justify-content: center
  .sound_button
    border: 2px solid black
    color: black
    background: white
    &:hover
      background: $second-color
      color: white 
      border: 2px solid  $second-color

  .sound_button_active
    color: white
    background: $second-color
    border: 2px solid $second-color


@media (max-width: 600px)
  .buttons
    flex-direction: column
    button
      margin: 21px 12px 12px
    .sound_buttons button
       margin: 10px 12px 16px