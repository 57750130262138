* 
  margin: 0
  padding: 0
  border: none
  outline: none
  box-sizing: border-box

html 
  font-size: 10px

body 
  display: flex
  flex-direction: column

.piano 
  height: 58vh

@media (max-width: 1000px)
  html 
    font-size: 8.5px
  .piano 
    height: 70vh

@media (max-width: 768px)
  html 
    font-size: 7.5px
  .piano 
    height: 79vh

@media (max-width: 650px)
  html 
    font-size: 5px
  .piano 
    height: 29vh

@media (max-width: 440px)
  html 
    font-size: 4px