$main-color: rgb(22, 124, 192) 

.song 
  position: relative
  text-align: center
  border: 1px solid black
  padding: 8px 26px 
  margin: 12px
  cursor: pointer
  border-radius: 100px
  &:hover
    background: $main-color
    color: white
    border: 1px solid $main-color 
    input 
     background-color: $main-color 
     color: white

  input
    height: 26px
    text-align: center
    width: 85px  
    font-size: 16.5px
    font-family: sans-serif

  .delete_button
    position: absolute
    top: -3px
    right: -5px
    background: #029217
    background: red
    color: white
    width: 7px
    height: 7px
    padding: 8px
    font-size: 9px
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    transition: 0.2s
    border-radius: 21px
    &:hover
      transform: scale(1.25) 
      background: red