$menu-width: 250px
$main-color: rgb(22, 124, 192)

.side_menu 
  position: fixed
  z-index: 2
  background-color: $main-color
  top: 0
  right: 0
  display: flex
  flex-direction: column
  align-items: center
  width: $menu-width
  height: 100%
  padding: 22px 12px
  font-size: 18px
  letter-spacing: 2.5px
  overflow: scroll
  transition: 0.28s
  transform: translateX($menu-width)
  .side_menu_title
    padding-top: 65px
    padding-bottom: 16px
  .song_item
    margin: 5px 0
    background-color: $main-color 
    display: flex
    input
      background-color: $main-color 
      color: white
      height: 26px
      text-align: center
      max-width: 90px  
      font-size: 16px
      letter-spacing: 2px
      font-family: sans-serif
    button
      display: flex
      align-items: center
      background: $main-color 
      color: white
      padding: 2px
      border-radius: 4px 
      margin-left: 5px
    .delete:hover
        background: red
    .play:hover
        background: green
    .stop:hover
        background: rgba(244, 228, 0, 0.946)

.show 
  transform: translateX(0)